import Home from "../pages/Home";
import MyVenues from "../pages/PropertyManager/MyVenues";
import SingleVenuePage from "../pages/PropertyManager/SingleVenuePage";
import CreateVenuePage from "../pages/PropertyManager/CreateVenuePage";
import FormPreview from "../pages/PropertyManager/FormPreview";
import VenueDetailPage from "../pages/RetreatHost/VenueDetailPage";
import CreateRetreatPage from "../pages/RetreatHost/Create-Retreat";
import WelcomePage from "../components/core/RetreatHost/Retreat/WelcomePage";
import CreateVenue from "../components/core/PropertyManager/CreateVenue";
import EditVenuePage from "../pages/PropertyManager/EditVenuePage";
import HostLayout from "../components/core/RetreatHost/HostDashboard/components/HostLayout";
import HostHome from "../components/core/RetreatHost/HostDashboard/HostRetreat/HostHome";
import HostRetreat from "../components/core/RetreatHost/HostDashboard/HostRetreat/HostRetreat";
import HostExplore from "../components/core/RetreatHost/HostDashboard/HostRetreat/HostExplore";
import HostMessage from "../components/core/RetreatHost/HostDashboard/HostRetreat/HostMessage";
import HostMetrics from "../components/core/RetreatHost/HostDashboard/HostRetreat/HostMetrics";
import Messages from "../pages/Messages";
import VenueHome from "../pages/PropertyManager/VenueHome";
import PropertyManagerDashboardLayout from "../components/core/PropertyManager/PropertyManagerDashboardLayout";
import PageNotFound from "../pages/PageNotFound";
import ReservationPage from "../pages/PropertyManager/ReservationPage";
import PayoutPage from "../pages/PropertyManager/PayoutPage";
import AdminBookings from "../components/core/Admin/Bookings/AdminBookings";
import AdminDashboard from "../pages/Admin/Dashboard";
import AdminUserList from "../pages/Admin/User/UserList";
import AdminUserDetail from "../pages/Admin/User/UserDetail";
import AdminVenueDetail from "../pages/Admin/Venue/VenueDetail";
import AdminVenueList from "../pages/Admin/Venue/VenueList";
import CancelVenueForm from "../pages/PropertyManager/VenueCancel/Index";
import AdminPayoutList from "../pages/Admin/Payouts/PayoutList";
import AdminMessages from "../pages/Admin/Messages";
import MyProfile from "../components/core/PropertyManager/Profile/MyProfile";
import AdminRetreatList from "../pages/Admin/Retreats/RetreatList";
import AdminReservationList from "../pages/Admin/Reservations/ReservationList";
import AdminRetreatDetail from "../pages/Admin/Retreats/RetreatDetail";
import AuthRedirectHandler from "../components/core/Authentication/AuthRedirectHandler";
import Logout from "../pages/Authentication/Logout";
import PaymentComplete from "../pages/PropertyManager/VenueCancel/PaymentComplete";
import { VenueManagerSignupHandler } from "../components/core/Authentication/VenueManagerSignupHandler";
import { RetreatHostSignupHandler } from "../components/core/Authentication/RetreatHostSignupHandler";
// import IndividualRetreat from "../components/core/RetreatHost/HostDashboard/IndividualRetreat/IndividualRetreat";

export const routes: any = [
  {
    path: "/",
    component: Home,
    protected: false,
  },
  {
    path: "/venues/:id",
    component: SingleVenuePage,
    protected: true,
    role: "venue_manager", // Only venue managers can access this route
  },
  {
    path: "/host/retreat/:retreatId",
    component: VenueDetailPage,
    protected: true,
    role: "retreat_host", // Only retreat hosts can access this route
  },
  {
    path: "/venues/create-venue",
    component: CreateVenuePage,
    protected: true,
    role: "venue_manager", // Only venue managers can access this route
  },
  {
    path: "/venues/create-venue/venue-details",
    component: CreateVenue,
    protected: true,
    role: "venue_manager",
  },
  {
    path: "/venues/preview/:id?",
    component: FormPreview,
    protected: true,
    role: "venue_manager", // Only venue managers can access this route
  },
  {
    path: "/host/retreat/create",
    component: CreateRetreatPage,
    protected: true,
    role: "retreat_host", // Only retreat hosts can access this route
  },
  {
    path: "/host/retreat",
    component: WelcomePage,
    protected: true,
    role: "retreat_host",
  },
  {
    path: "/host",
    component: HostLayout,
    protected: true,
    children: [
      {
        path: "/host/dashboard",
        component: HostHome,
        protected: true,
        role: "retreat_host",
      },
      {
        path: "/host/my-retreats",
        component: HostRetreat,
        protected: true,
        role: "retreat_host",
      },
      {
        path: "/host/explore",
        component: HostExplore,
        protected: true,
        role: "retreat_host",
      },
      {
        path: "/host/messages",
        component: HostMessage,
        protected: true,
        role: "retreat_host",
      },
      {
        path: "/host/metrics",
        component: HostMetrics,
        protected: true,
        role: "retreat_host",
      },
    ],
  },
  {
    path: "*",
    component: PageNotFound,
    protected: true,
  },
  {
    path: "/venues/payment-complete",
    component: PaymentComplete,
    protected: false,
  },
  // Venue route for cancellation
  {
    path: "/venues/cancel",
    component: CancelVenueForm,
    protected: true,
    role: "venue_manager",
  },
  {
    path: "/venues",
    component: PropertyManagerDashboardLayout,
    protected: true,
    children: [
      {
        path: "/venues/home",
        component: VenueHome,
        protected: true,
        role: "venue_manager",
      },
      {
        path: "/venues/my-venues",
        component: MyVenues,
        protected: true,
        role: "venue_manager",
      },
      {
        path: "/venues/reservations",
        component: ReservationPage,
        protected: true,
        role: "venue_manager",
      },
      {
        path: "/venues/payouts",
        component: PayoutPage,
        protected: true,
        role: "venue_manager",
      },
      {
        path: "/venues/messages",
        component: Messages,
        protected: true,
        role: "venue_manager",
      },
      {
        path: "/venues/my-venues/edit-venue/:venue_id",
        component: EditVenuePage,
        protected: true,
        role: "venue_manager",
      },
      {
        path: "/venues/profile",
        component: MyProfile,
        protected: true,
        role: "venue_manager",
      },
    ],
  },
  // Admin routes
  {
    path: "/admin/dashboard",
    component: AdminDashboard,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/users",
    component: AdminUserList,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/users/:user_id",
    component: AdminUserDetail,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/venues",
    component: AdminVenueList,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/venues/:venueId",
    component: AdminVenueDetail,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/payouts",
    component: AdminPayoutList,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/retreats",
    component: AdminRetreatList,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/retreats/:retreatId",
    component: AdminRetreatDetail,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/reservations",
    component: AdminReservationList,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/bookings",
    component: AdminBookings,
    protected: true,
    role: "admin",
  },
  {
    path: "/admin/messages",
    component: AdminMessages,
    protected: true,
    role: "admin",
  },
  // Authentication
  {
    path: "/auth/",
    component: AuthRedirectHandler,
    protected: false,
  },
  {
    path: "/admin/auth",
    component: AuthRedirectHandler,
    protected: false,
  },
  {
    path: "/auth/host",
    component: RetreatHostSignupHandler,
    protected: false,
  },
  {
    path: "/auth/venue",
    component: VenueManagerSignupHandler,
    protected: false,
  },
  {
    path: "/logout/",
    component: Logout,
    protected: false,
  },
];
