/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

// Third Party
import { useNavigate } from "react-router-dom";
import Loader from "../../UI/Loader";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

// Component
import { LogoutComponent } from "./Logout";

// network
import { createUserProfile } from "../../../network";

// Utility
import { decodeAndCheckToken } from "../../../library";
import {
  createUserFirebase,
  updateUserDetailFirebase,
} from "../../../utils/firebase";

// APIs
import { useApiCall } from "../../../hooks/useApiCall";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

// Firebase
import { db, handleSignInAnonymously } from "../../../network/firebaseConfig";
import { get, ref } from "firebase/database";

const AuthRedirectHandler = () => {
  const { call } = useApiCall();
  const navigate = useNavigate();

  const userProfile = useSelector((state: RootState) => state.user.userProfile);

  const [loading, setLoading] = useState(true); // Track the loading state
  const [redirecting, setRedirecting] = useState(false); // Track redirection state

  // APIs
  const createUserProfileData = async ({ token }: { token: string }) => {
    call(
      () => createUserProfile({ token }),
      (res) => {
        const {
          data: { data },
        } = res;
        const registeredUserData = data;
        // Create user in Firebase
        registeredUserData &&
          createUserFirebase({
            uid: registeredUserData.id || "",
            name: registeredUserData.full_name || "",
            email: registeredUserData.email || "",
            userRole: registeredUserData.user_type || "",
          });
      },
      (err) => {
        toast.error(err?.response?.data?.message || "Failed to create user");
        setLoading(false); // Stop loading if API call fails
      }
    );
  };

  const handleAuthenticationToken = async () => {
    const hash = window.location.hash;

    if (hash) {
      // Remove the leading '#' character and convert to URLSearchParams
      const params = new URLSearchParams(hash.substring(1));

      // Extract the 'id_token' from the parameters
      const token = params.get("id_token");

      if (token) {
        const { isAuthenticated, userData } = decodeAndCheckToken(
          token?.toString() || ""
        );

        // Check if redirected from signup
        if (userData?.tfp?.includes("signup")) {
          // Remove token from cookies if any
          Cookies.remove("userToken");

          // Create user profile data in backend
          await createUserProfileData({ token });

          setLoading(false); // Stop loading once user profile is created
          navigate("/logout"); // Redirect to logout page after signup
          return;
        } else {
          if (isAuthenticated) {
            // Set token in cookies
            Cookies.set("userToken", token, { expires: 1 });

            // Handle sign in anonymously for Firebase Auth
            handleSignInAnonymously();

            // Stop loading and proceed with redirection
            setLoading(false);
            setRedirecting(true); // Start redirecting
            return; // Skip further processing
          } else {
            console.error("AuthRedirectHandler -> Token is invalid or expired");
            setLoading(false); // Stop loading if not authenticated
            setRedirecting(true);
          }
        }
      }
    }

    setLoading(false); // Stop loading if hash is not found or token is invalid
  };

  const updateUserDetails = async () => {
    if (userProfile) {
      const userRef = ref(db, `users/${userProfile.user_id}`);

      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        updateUserDetailFirebase(userProfile.user_id, {
          uid: userProfile.user_id || "",
          name: userProfile.full_name || "",
          email: userProfile.email || "",
          userRole: userProfile?.user_type || "",
        });
      } else {
        // Add user data to Firebase Realtime Database
        createUserFirebase({
          uid: userProfile.user_id || "",
          name: userProfile.full_name || "",
          email: userProfile.email || "",
          userRole: userProfile?.user_type || "",
        });
      }
    }
  };

  useEffect(() => {
    handleAuthenticationToken();
  }, []); // Run only once when the component is mounted

  useEffect(() => {
    if (userProfile.user_id) {
      updateUserDetails();
    }

    if (!loading && !userProfile.profileApiPending && redirecting) {
      // Ensure we don't navigate until profile API request is completed
      switch (userProfile?.user_type) {
        case "venue_manager":
          navigate("/venues/home");
          break;
        case "admin":
          navigate("/admin/dashboard");
          break;
        case "retreat_host":
          navigate("/host/dashboard");
          break;
        // Default route for "retreat_guest"
        case "retreat_guest":
          navigate("/venues/home");
          break;
        default:
          navigate("/venues/home");
          break;
      }
    }
  }, [userProfile, loading, navigate, redirecting]); // Dependencies on userProfile, loading state, and redirection flag

  return (
    <>
      {loading ? (
        <Loader /> // Show loading spinner until token is processed
      ) : (
        // Render the LogoutComponent only if something went wrong during the login flow
        <>
          {userProfile.profileApiPending ? (
            <Loader />
          ) : (
            <LogoutComponent pageRedirect="sessionExpired" />
          )}
        </>
      )}
    </>
  );
};

export default AuthRedirectHandler;
