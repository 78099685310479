/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

// Third Party
import Loader from "../../UI/Loader";
import Cookies from "js-cookie";

// Component
import { LogoutComponent } from "./Logout";

// network
import { createUserProfile } from "../../../network";

// Utility
import { decodeAndCheckToken } from "../../../library";
import { createUserFirebase } from "../../../utils/firebase";

// APIs
import { useApiCall } from "../../../hooks/useApiCall";

interface SignupHandlerProps {
  userRole: "venue_manager" | "retreat_host";
}

const SignupRedirecthandler: React.FC<SignupHandlerProps> = ({ userRole }) => {
  const { call } = useApiCall();

  const [loading, setLoading] = useState(true);

  const loginUrl =
    userRole === "venue_manager"
      ? `${process.env.REACT_APP_VENUE_MANAGER_LOGIN_URL}`
      : `${process.env.REACT_APP_RETREAT_HOST_LOGIN_URL}`;

  // APIs
  const createUserProfileData = ({ token }: { token: string }) => {
    call(
      () => createUserProfile({ token }),
      (res) => {
        const {
          data: { data },
        } = res;

        const registeredUserData = data;

        // Create user in Firebase
        registeredUserData &&
          createUserFirebase({
            uid: registeredUserData.id || "",
            name: registeredUserData.full_name || "",
            email: registeredUserData.email || "",
            userRole: registeredUserData.user_type || "",
          });
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleAuthenticationToken = () => {
    const hash = window.location.hash;

    if (hash) {
      // Remove the leading '#' character and convert to URLSearchParams
      const params = new URLSearchParams(hash.substring(1));

      // Extract the 'id_token' from the parameters
      const token = params.get("id_token");

      if (token) {
        const { isAuthenticated, userData } = decodeAndCheckToken(
          token?.toString() || ""
        );

        if (isAuthenticated) {
          createUserProfileData({ token });
          setLoading(false);
          return;
        }
      }
      // Redirect to login page if something went wrong
      window.location.href = loginUrl;
      return;
    }
  };

  useEffect(() => {
    Cookies.remove("userToken");
    handleAuthenticationToken();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <LogoutComponent pageRedirect="sessionExpired" />
        </>
      )}
    </>
  );
};

export default SignupRedirecthandler;
