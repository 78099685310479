import React, { useEffect, useState } from "react";

// Third Party
import Cookies from "js-cookie";

// Component
import Loader from "../../UI/Loader";

// Utility
import { GetQueryParamsFromURL } from "../../../library";

export const handleLogout = () => {
  // Delete token from cookies
  Cookies.remove("userToken");
  window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
  return (
    <>
      <Loader />
    </>
  );
};

// Define a type for pageRedirect options
type PageRedirectTypes = string | undefined | null;

export const LogoutComponent = ({
  pageRedirect,
}: {
  pageRedirect?: PageRedirectTypes;
}) => {
  const logoutType = GetQueryParamsFromURL().get("type");

  const pageRedirectType: PageRedirectTypes = logoutType || pageRedirect;

  const [timer, setTimer] = useState(1);

  useEffect(() => {
    if (!logoutType && !pageRedirect) {
      handleLogout();
    }
  }, [logoutType, pageRedirect]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    // Clear the interval after 5 seconds
    if (timer === 0) {
      clearInterval(countdown);
      handleLogout();
    }

    return () => clearInterval(countdown);
  }, [timer]);

  // Render the appropriate message and handle redirection
  const LogoutRedirect: React.FC<{ pageRedirectType: PageRedirectTypes }> = ({
    pageRedirectType,
  }) => {
    return (
      <div>
        <Loader />
      </div>
    );
  };

  return <LogoutRedirect pageRedirectType={pageRedirectType} />;
};
