import { useEffect } from "react";

// third party
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

import "bootstrap/dist/css/bootstrap.min.css";
// import "react-multi-carousel/lib/styles.css";
import { useDispatch } from "react-redux";
import AppRoutes from "./AppRoutes";

// redux
import { fetchIconsThunk } from "./redux/features/common-slice";
import { AppDispatch } from "./redux/store";
import { fetchUserProfileThunk } from "./redux/features/user-slice";

// styles
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";
import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    // NOTE : Exclude below routes from fetching icons and user profile
    const pathName = window.location.pathname;
    if (!pathName.includes("logout")) {
      // Dispatch the fetchIconsThunk when the component mounts
      dispatch(fetchIconsThunk());

      // If user Token is availbale then and then fetch user profile
      Cookies.get("userToken") && dispatch(fetchUserProfileThunk());
    }
  }, [dispatch]);
  return (
    <div className="App">
      <ToastContainer position="bottom-right" />
      <AppRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
